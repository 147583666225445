import React from "react";
import {cloneDeep} from "lodash";
import {
    DocumentCard,
    DocumentCardActions,
    DocumentCardDetails,
    Dropdown, Icon,
    IDocumentCardStyles,
    IDropdownOption,
    Separator,
    TextField
} from "@fluentui/react";
import EditableImage from "./EditableImage";
import {IProductImage} from "../../models/Search";
import {PLACEHOLDER_IMAGE} from "../../Constants";
import Header from "../Common/Header";

interface IProductImagesProps {
    productId?: string;
    title?: string;
    images: IProductImage[]
    editMode?: boolean;
    thumbnail?: string;
    onThumbnailChange?: (url: string) => void;
    onUpdate?: React.Dispatch<React.SetStateAction<IProductImage[]>>;
    onSetExternalProduct: (image: IProductImage) => void;
    onDelete?: (image: IProductImage) => void;
}
export const ProductImages: React.FC<IProductImagesProps> = (props) => {
    const updateImageName = (value: string, index?: number) => {
        if (!props.onUpdate || index == null) return;
        props.onUpdate((images) => {
            return images.map((image, i) => i === index ? { ...image, name: value } : image
            );
        })
    }
    const updateImageType = (value: string, index?: number) => {
        if (!props.onUpdate || index == null) return;
        props.onUpdate((images) => {
            return images.map((image, i) => i === index ? { ...image, type: value } : image
            );
        })
    }

    const updateImageUrl = (url: string, index?: number) => {
        if (!props.onUpdate || index == null) return;
        props.onUpdate((images) => {
            return images.map((image, i) => i === index ? { ...image, url: url } : image
            );
        })
    }

    const deleteImage = (index?: number) => {
        if (!props.onUpdate || index == null) return
        props.onUpdate((images) => {
            return images.filter((_, i) => i !== index);
        })
    }

    if (!props.images && !props.editMode) return null;
    const cardStyles: IDocumentCardStyles = {
        root: { display: 'inline-block', marginRight: 20, width: 250, padding: 10 },
        
    };

    const onSetAsCurrentActionClick = (image: IProductImage, ev: React.SyntheticEvent<HTMLElement>): void => {
        props.onSetExternalProduct(image);
        ev.stopPropagation();
        ev.preventDefault();
    };

    const setupNewCard = () => {
        if (!props.onUpdate) return
        const newId = crypto.randomUUID();
        const newImages = cloneDeep(props.images) ?? [];
        
        newImages.push({name: "", type: "product", url:PLACEHOLDER_IMAGE , id: newId, isCustom:true})

        props.onUpdate(newImages);
    }

    const onCreateNewActionClick = (ev: React.SyntheticEvent<HTMLElement>): void => {
        setupNewCard();
        ev.stopPropagation();
        ev.preventDefault();
    };

    if (!props.editMode &&
        (props.images == null || props.images.length === 0) &&
        (props.thumbnail == null || props.thumbnail === PLACEHOLDER_IMAGE || props.thumbnail === ''))
        return <div>
            {props.title && <Separator>{props.title}</Separator>}
            <Header icon={"FabricFolderFill"}>
                No Product Images Found
            </Header>
        </div>
    
    return <div>
        {props.title && <Separator>{props.title}</Separator>}
        {(props.thumbnail || props.editMode) && <ProductImageCard
            key={'thumbnail'}
            image={{name: 'Thumbnail', type: 'product', url: props.thumbnail ?? PLACEHOLDER_IMAGE, id: 'thumbnail', isCustom: false}}
            onImageChange={(url) => props.onThumbnailChange?.(url)}
            editMode={props.editMode}
            cardStyles={cardStyles}
            editImageOnly
            />}
            {props.images.map((productImage, i) => 
                <ProductImageCard
                    key={productImage.id}
                    index={i}
                    image={productImage}
                    onImageChange={updateImageUrl}
                    onNameChange={updateImageName}
                    onTypeChange={updateImageType}
                    onDelete={deleteImage}
                    editMode={props.editMode}
                    cardStyles={cardStyles}
                />)
            }
        {props.editMode ? <div className={"add-product-image-card"}>
            <p className={"ac-product-image-add-icon"}>
                <Icon iconName={'Add'} onClick={onCreateNewActionClick} />
            </p>
        </div>: <></>}
    </div>
};


interface IProductImageCartProps {
    index?: number;
    image: IProductImage;
    editImageOnly?: boolean;
    onImageChange: (url: string, index?: number) => void;
    onNameChange?: (name: string, index?: number) => void;
    onTypeChange?: (type: string, index?: number) => void;
    onDelete?: (index?: number) => void;
    editMode?: boolean;
    cardStyles?: IDocumentCardStyles;
}

const ProductImageCard: React.FC<IProductImageCartProps> = (props) => {
    const typeOptions: IDropdownOption[] = [
        { key: 'product', text: 'Product Image' },
        { key: 'marketing', text: 'Marketing'},
        { key: 'internal', text: 'Internal' },
        { key: 'technical', text: 'Technical' }
    ];
    const getTypeLabel = (value : string) =>{
        const selection = typeOptions.filter(a => a.key == value)
        if(selection[0]){
            return selection[0].text
        }
        return value ?? "";
    }
    if (props.editMode) {
        return (
            <DocumentCard
                key={`document-card-edit-${props.image.id}`}
                aria-label={'Product Image Card'}
                styles={props.cardStyles}
            >
                <EditableImage
                    image={props.image}
                    sizeSquare={240}
                    editMode={true} 
                    onChange={(url) => props.onImageChange(url, props.index)}
                    />
                <DocumentCardDetails>
                    <TextField
                        label='Image Name'
                        placeholder={'Please Enter an Image Name'}
                        value={props.image.name}
                        onChange={(e, value) => props.onNameChange?.(value ?? '', props.index)}
                        disabled={props.editImageOnly}
                    />
                    <Dropdown
                        options={typeOptions}
                        selectedKey={ props.image.type }
                        label='Image Type'
                        onChange={(e, d) => d && props.onTypeChange?.(d.key.toString(), props.index)}
                        placeholder= 'Select an Image Type'
                        disabled={props.editImageOnly}
                    />
                </DocumentCardDetails>
                <DocumentCardActions
                    actions={
                        props.editImageOnly ? []
                        : [{
                            iconProps: {iconName: 'Delete'},
                            onClick: () => props.onDelete?.(props.index),
                            ariaLabel: 'delete product image',
                        }]
                    } 
                    styles={{root:{padding: 2, alignItems: "left"}}}
                    />
            </DocumentCard>
        )
    }
    return (
        <DocumentCard
            key={`document-card-${props.image.id}`}
            aria-label={
                'Product Image Card'
            }
            styles={props.cardStyles}
            onClickHref={props.image.url}
            onClickTarget={"new"}
        >
            <EditableImage image={props.image} sizeSquare={220} editMode={false}/>
            <div className={'labeled-content-container'}>
                {props.image.name && <div>
                    <span style={{fontWeight: 'bold'}}>Image Name:</span> {props.image.name}
                </div>}
                {props.image.type && <div>
                    <span style={{fontWeight: 'bold'}}>Image Type:</span> {getTypeLabel(props.image.type)}
                </div>}
            </div>
        </DocumentCard>
    )
}